import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import logo from "./../assets/us2nigeria_logo_Resised.png";
import back_img from "./../assets/banner.png";
import back_img_two from "./../assets/Air_cargo.png";
import back_img_three from "./../assets/pic_3.png";
import back_img_four from "./../assets/pic_4.png";
import Jumbotron from 'react-bootstrap/Jumbotron';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import Carousel from 'react-bootstrap/Carousel'
import { MDBIcon, MDBContainer, MDBBtn } from 'mdbreact';

const useStyles = makeStyles((theme) => ({
    root: {
        //flexGrow: 1,
        backgroundColor: "#FAFAD2"
    },
    paperStyle:{
        padding: "1.5em",
        backgroundColor: "#FAFAD2"
    },
    header:{
        flexGrow: 1,
    },
    a: {
        marginLeft: "auto",
    },
    button:{
        color: "#1b8746",
        fontSize: "1.2em"
    },
    logo: {
        flexGrow: 1,
        maxWidth: 200,
    },
    media: {
        paddingTop: '2em',
        maxWidth: "40em",
    },
    p: {
        fontSize: '2em'
    },
    p1: {
        color: 'red'
    },
    p2: {
        color: 'green'
    }
  }));

function Home(){
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <AppBar position="static" className={classes.paperStyle}>
                <Toolbar className={classes.header}>
                    {/* <Typography variant="h1" className={classes.title}> */}
                        <img
                            src={logo}
                            alt={"US2Nigeria"}
                            className={classes.logo}
                        />
                    {/* </Typography> */}
                    <a className={classes.a} target={"_blank"} href={"https://api.whatsapp.com/send/?phone=14705045787&text=Hi%2C+I%27d+like+to+order+an+item&app_absent=0"}>
                        <p className={classes.button}>Contact Us</p>
                    </a>
                </Toolbar>
            </AppBar>
            <Jumbotron fluid style={{backgroundColor: "white"}}>
                <Container>
                    <h1>Please contact us for items from any stores in the <span className={classes.p1}>USA</span> or in <span className={classes.p2}>Nigeria</span></h1><br/>
                    <Carousel>
                        <Carousel.Item interval={3000}>
                            <Image src={back_img} fluid />
                        </Carousel.Item>
                        <Carousel.Item interval={5000}>
                            <Image src={back_img_two} fluid />
                        </Carousel.Item>
                        <Carousel.Item interval={5000}>
                            <Image src={back_img_three} fluid />
                        </Carousel.Item>
                        <Carousel.Item interval={5000}>
                            <Image src={back_img_four} fluid />
                        </Carousel.Item>
                    </Carousel>
                    {/* <Image src={back_img} fluid /> */}
                    <p style={{paddingTop: "1em"}}>
                        <a target={"_blank"} href={"https://api.whatsapp.com/send/?phone=14705045787&text=Hi%2C+I%27d+like+to+order+an+item&app_absent=0"}>
                            <Button variant="success" size="lg">Contact us</Button>{' '}
                        </a>
                    </p>
                </Container>
            </Jumbotron>
            <MDBContainer>
                <a style={{color: "white"}} target={"_blank"} href={"https://www.facebook.com/ustonigeria/"}>
                    <MDBBtn size="lg" tag="a" floating social="fb">
                        <MDBIcon fab icon="facebook-f" />
                    </MDBBtn>
                </a>
                <a style={{color: "white"}} target={"_blank"} href={"https://api.whatsapp.com/send/?phone=14705045787&text=Hi%2C+I%27d+like+to+order+an+item&app_absent=0"}>
                    <MDBBtn size="lg" tag="a" floating social="tw">
                        <MDBIcon fab icon="whatsapp" />
                    </MDBBtn>
                </a>
                <a style={{color: "white"}} target={"_blank"} href={"https://www.instagram.com/us2nigeria/"}>
                    <MDBBtn size="lg" tag="a" floating social="tw">
                        <MDBIcon fab icon="instagram" />
                    </MDBBtn>
                </a>
            </MDBContainer>
            <footer style={{paddingTop: ".7em"}}>
                <cite title="Source Title">© Copyright US2Nigeria 2021</cite>
            </footer>
        </div>
    )
}

export default Home;